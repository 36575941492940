<template>
  <div class="container">
    <div class="content flex flex-between">     
      <div class="answer-condition" v-if="!isCan">
        <h2 class="title">IP规划师模拟考试</h2>
        <div class="text">
          <div class="label">
            符合以下条件的人员，可以参加IP规划师职业资格模拟考试（理论）:
          </div>
          <p>（1）拥护中华人民共和国宪法</p>
          <p>（2）已报名参加线上、线下课程学习</p>          
        </div>
        <div class="text">
          <div class="label">
            考试内容：
          </div>
          <p>模拟考试包括单选题、判断题和多选题，共计65题，总分100分</p>
          <p>（1）单选题共30题，每题1分，错误不得分；</p>
          <p>（2）多项选择题共20题，每题2分，少选或错选不得分；</p>  
          <p>（3）判断题共15题，每题2分，错误不得分；</p>        
        </div>
        <div class="grade-form">
          <div class="label">考试等级</div>
          <el-select
            v-model="form.ipType"
            placeholder="请选择"
            @change="changeIpType"
          >
            <el-option
              v-for="(item, index) in ipTypeExamList"
              :key="'ipType' + index"
              :label="item.examName"
              :value="item.ipType"
            >
            </el-option>
          </el-select>
          <div class="btns">
            <el-button
              type="primary"
              class="enter"
              @click="checkHasCanExam"
              >开始答题</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="dialogbox"
      width="450px"
    >      
      <div class="close" @click="dialogVisible = false">
        <img src="@/assets/icons/grayclose.png" />
      </div>
      <div class="dialog-content">
        <div class="img"><img src="@/assets/images/img-certificate.png" alt=""></div>
        <div class="text">抱歉，您目前无法进行模拟考试<br>
请先进行培训报名</div>
        
        <el-button type="primary" class="signup" @click="$router.push({name:'CourseIndex'})">去报名</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config";
export default {
  data() {
    return {
      config,
      qntoken:'',
      isCan:0,
      dialogVisible: false,
      ipTypeExamList: [],
      form:{
        ipType:null,// 课程等级  1初级  2中级  3高级  4资深        
      },
    };
  },
  created() {
    this.getQnToken();
    this.getCommonList();
  },
  methods: {
    getQnToken() {
      this.$store.dispatch("GetUploadToken").then((res) => {
        this.qntoken = res.data.qnToken;
      });
    },
    getCommonList() {
      this.$http.get("/api/web/common/getCommonList").then((res) => {
        this.ipTypeExamList = res.data.ipTypeExamList;
        this.form.ipType = 1;
      });
    },   
    checkHasCanExam() {
      this.$http
        .get("/api/web/question/checkHasCanAnswer?ipType=" + this.form.ipType)
        .then((res) => {
          this.isCan = res.data.isCan;
          if (!this.isCan) {
            this.dialogVisible = true;
          } else {
            this.$router.push({
              name:'SingleTest',
              query:{
                ipType:this.form.ipType
              }
            }) 
          }
        });
    },
    changeIpType() {
      let index = this.ipTypeExamList.findIndex(
        (item) => item.ipType == this.form.ipType
      );
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  padding: 30px 0;
  background-color: #f5f5f5;
  .content {
    width: var(--container-width);
    margin: 0 auto;
    .answer-condition {
      width: 100%;
      min-height: 500px;
      text-align: center;
      padding: 50px 405px;
      border-radius: 10px;
      background-color: #fff;
      .title{
        font-size: 30px;
        color: #000;
        margin-bottom: 40px;
        text-align: center;
        margin-top: 0;
      }
      .text {
        text-align: left;
        .label {
          font-size: 16px;
          font-weight: 700;
          color: #333;
          text-align: left;
        }
        h5{
          margin-bottom: 0;
        }
      }
      .grade-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 40px;
        .label {
          font-size: 16px;
          font-weight: 700;
          color: #333;
          text-align: left;
        }
        /deep/.el-select {
          width: 440px;
          margin-top: 20px;
          margin-bottom: 50px;
          .el-input {
            .el-input__inner {
              height: 50px;
            }
          }
        }
        .btns {
          width: 100%;
          .el-button {
            height: 40px;
            padding: 0 37px;
            font-size: 16px;
            color: #d71a18;
            border: 1px solid #d71a18;
            &.el-button--primary {
              color: #fff;
            }
          }
        }
      }
    }
  }
  /deep/.dialogbox {
    text-align: left;
    
    .el-dialog {
      width: 100%;
       border-radius: 15px;
    overflow: hidden;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      img {
        display: block;
        width: 36px;
        height: 36px;
      }
    }
    .dialog-content {
     
      padding: 40px;
      text-align: center;
        .text {
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #d71a18;
        }
        .img {
          img {
            display: block;
            width: 175px;
            height: 175px;
            margin: 0 auto 40px;
          }
        }
        .signup{
          width: 125px;
          border-radius: 20px;
          margin-top: 30px;
        }
      
    }
  }
}
</style>
